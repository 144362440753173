import { Component, OnInit, OnDestroy } from '@angular/core';
import { applicationConfig } from '../environments/application';
import { environment } from '../environments/environment';
import { LanguageService } from 'projects/_shared/shared/helper-service/language.service';
import { Language } from '../../../_shared/models/enums'
import { UserService } from 'projects/_shared/shared/helper-service/user.service';
import { FooterMenuModel } from 'customerdigital-ui-lib';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { route, RouterService } from 'projects/_shared/shared/helper-service/router.service';
import { IAppState } from 'projects/_shared/shared/store/app.store';
import { HeaderMenuItemType, FooterMenuItemType } from './shared/enum';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Subscription, first } from 'rxjs';
import { AccessibilityHelperService, TimeoutService, UrlBuilderService } from 'customerdigital-service-lib';
import { Constants } from 'projects/_shared/shared/constants';
import { HeaderMenuItem } from 'projects/_shared/models/header.model';
import { IconType } from 'projects/_shared/models/enums';
import * as _ from 'lodash';
import { EpaasHelperService } from 'projects/_shared/shared/helper-service/epaas-helper.service';
import { FeatureIndicatorService } from 'projects/_shared/web-service/feature-indicator.service';
import { BooleanModel } from 'projects/_shared/models/boolean.model';
import { FilterService } from 'projects/_shared/shared/helper-service/filter.service';
import { Store } from '@ngrx/store';
import { AppTileModel } from 'projects/_shared/models/app-tile.model';
import { SessionTimeoutModel } from 'projects/_shared/shared/models/sessionTimeout.model';
import { SessionTimeoutService } from 'projects/_shared/shared/helper-service/session-timeout.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  sessionTimeoutConfig!:SessionTimeoutModel;
  idleTime!:number;
  timeoutTime!:number;
  footerMenuItems = new Array();
  headerMenuItems = new Array();
  tempFooterMenuItems = new Array();
  hasLoggedInStateSubscription: Subscription = new Subscription();
  routerEventSubscribe: any;
  selectedIndex = 0;
  privacyUrl: any;
  domainUrl: any;
  copyRightText: string = "";
  public brand = applicationConfig.BRAND;
  public country = applicationConfig.COUNTRY;
  public environment = environment.ENV;
  dataloaded: boolean = false;
  dataloadedSubScription: Subscription = new Subscription();
  public allMenuItem!: any[];
  public footerMenuList: any[] = [];
  public epassInitializedSubscribe: any;
  isEpaasError: boolean = false;

  constructor(private languageService: LanguageService,
    private userService: UserService,
    private router: Router,
    private timeoutService: TimeoutService,
    private sessionTimeoutService: SessionTimeoutService,
    private routerService: RouterService,
    private store: Store<IAppState>,
    private translateService: TranslateService,
    private urlBuilderService: UrlBuilderService,
    private accessibilityService: AccessibilityHelperService,
    private routes: ActivatedRoute,
    private epaasHelperService: EpaasHelperService,
    private featureIndicatorService: FeatureIndicatorService,
    private filterService: FilterService) {
    this.routes.queryParams.subscribe(params => {
      let language = params['language'];
      if (language != undefined) {
        switch (language) {
          case Language.English:
            this.toogleToEnglish();
            break;
          case Language.French:
            this.toogleToFrench();
            break;
        }
      }
    });
    this.dataloadedSubScription = this.store.select<boolean | undefined>(state => state.DataLoaded)
      .subscribe(state => {
        this.dataloaded = state!;
      });
  }



  ngOnInit(): void {
    this.store.select(x=> x.EnvironmentConfig?.IDLE_SESSION).subscribe(s=> this.idleTime = s ?? 0)
    this.store.select(x=> x.EnvironmentConfig?.TIME_OUT_SESSION).subscribe(s=> this.timeoutTime = s ?? 0)
    this.sessionTimeoutService.handleIdleSessionTimeOut();
    this.translateService.onDefaultLangChange.pipe(first()).subscribe((event: LangChangeEvent) => {
      this.epaasHelperService.initializeEpaas();
    });
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.initializeHeader();
      this.initializeFooter();
      this.filterFooterMenu();
    });
    this.routerEventSubscribe = this.router.events.subscribe((event) => {

      if (event instanceof NavigationEnd) {
        if (this.userService.isAuthenticated()) {
          this.timeoutService.initIdle(this.idleTime * 60, this.timeoutTime * 60);
          this.routerEventSubscribe.unsubscribe();
        }
      }
    });

    this.initializeHeader();
    this.initializeFooter();
  }

  private filterFooterMenu(): void {
    this.store.select<BooleanModel | undefined>(state => state.CannotAuthenticate)
      .subscribe(error => {
        this.isEpaasError = error! && error.booleanValue;
      });
    this.epassInitializedSubscribe = this.store.select<BooleanModel | undefined>(state => state.IsEpassInitialized).subscribe(
      (value) => {
        if (value && value.booleanValue) {
          if (this.isEpaasError) {
            this.footerMenuList = this.filterService.filterFooterMenu(this.footerMenuItems, FooterMenuItemType.CookiePolicy);
          } else {
            this.footerMenuItems = this.tempFooterMenuItems;
            this.footerMenuList = this.filterService.filterFooterMenu(this.footerMenuItems);
          }
        }
        else {
          this.footerMenuList = this.filterService.filterFooterMenu(this.footerMenuItems, FooterMenuItemType.CookiePolicy);
        }
      }
    )
    this.footerMenuItems = this.footerMenuList;
  }

  toogleToEnglish() {
    this.selectedIndex = 0;
    this.languageService.setCurrentLanguage(Language.English);
  }

  toogleToFrench() {
    this.selectedIndex = 1;
    this.languageService.setCurrentLanguage(Language.French);
  }

  public get isAuthenticated(): boolean {
    return this.userService.isAuthenticated();
  }

  private initializeHeader() {
    let myAccountHeaderMenuItem: HeaderMenuItem, editHeaderMenuItem: HeaderMenuItem, logoutHeaderMenuItem: HeaderMenuItem;
    let myAccountOptionKey: string = 'top-navigation.dropdown.option-myaccount';
    let editOptionKey: string = 'top-navigation.dropdown.option-edit';
    let logoutOptionKey: string = 'top-navigation.dropdown.option-logout';
    this.translateService.get([myAccountOptionKey, editOptionKey, logoutOptionKey]).subscribe(result => {
      myAccountHeaderMenuItem = new HeaderMenuItem(result[myAccountOptionKey], IconType.MyAccount, false, HeaderMenuItemType.MyAccount);
      editHeaderMenuItem = new HeaderMenuItem(result[editOptionKey], IconType.Edit, false, HeaderMenuItemType.EditUsername);
      logoutHeaderMenuItem = new HeaderMenuItem(result[logoutOptionKey], IconType.Logout, false, HeaderMenuItemType.Logout);
      this.headerMenuItems = [];
      this.headerMenuItems.push(logoutHeaderMenuItem);
      this.addHeaderMenuItems([myAccountHeaderMenuItem, editHeaderMenuItem]);
    });

    this.accessibilityService.setRoleOfOverlay();
  }

  private addHeaderMenuItems(headerMenuItems: HeaderMenuItem[]) {
    if (this.userService.isTermsAccepted()) {
      this.headerMenuItems.unshift(...headerMenuItems);
    }
  }

  public onMenuItemSelect(eventName: HeaderMenuItemType) {
    switch (eventName) {
      case HeaderMenuItemType.MyAccount:
        this.navigateToMyAccountProfile();
        break;
      case HeaderMenuItemType.EditUsername:
        this.editUsername();
        break;
      case HeaderMenuItemType.Logout:
        this.logout();
        break;
    }

  }

  public logoLinkClick() {
    this.navigateToMyAccountProfile();
  }

  private navigateToMyAccountProfile() {
    let clientConfig: AppTileModel[];
    this.store.select(state => state.EnvironmentConfig!.CLIENT_CONFIG).subscribe(x => clientConfig =x)
    let myAccountConfig = _.filter(clientConfig!, function (config) {
      return config && config.tileClass == Constants.myAccountTile;
    });

    if (myAccountConfig != undefined) {
      this.routerService.navigateToMyAccountDeepLink(myAccountConfig[0].loginUri, Constants.MyAccountProfilePage)
    }
  }

  private editUsername() {
    let externalUrl = this.urlBuilderService.getEditUsernameRedirectUrl();
    this.routerService.navigateToExternalUrl(externalUrl, true);
  }

  private logout() {
    this.routerService.navigateToSSOLogout();
  }


  private initializeFooter() {
    this.translateService.get("footer.copyright-text", { currentYear: new Date().getFullYear() }).subscribe(res => {
      this.copyRightText = res;
    });

    let languageEnglishFooterMenuItem: FooterMenuModel, languageFrenchFooterMenuItem: FooterMenuModel, privacyFooterMenuItem: FooterMenuModel, termsFooterMenuItem: FooterMenuModel, faqFooterMenuItem: FooterMenuModel, cookiePolicyFooterMenuItem: FooterMenuModel, contactUsFooterMenuItem: FooterMenuModel, bmwCaFooterMenuItem: FooterMenuModel;
    let languageEnglishKey: string = 'footer.language-english';
    let languageFrenchKey: string = 'footer.language-french';
    let privacyPolicyKey: string = 'footer.privacy-policy';
    let termsConditionsKey: string = 'footer.terms-conditions';
    let faqKey: string = 'footer.faq';
    let cookiePolicyKey: string = 'footer.cookie-policy';
    let contactusKey: string = 'footer.contact-us';
    let bmwCaKey: string = 'footer.bmw-ca';
    let privacyPolicyUrlKey: string = 'footer.privacy-policy-url';
    let domainUrlKey: string = 'footer.domain-url';

    this.translateService.get([languageEnglishKey, languageFrenchKey, privacyPolicyKey, termsConditionsKey, faqKey, cookiePolicyKey, contactusKey, bmwCaKey, privacyPolicyUrlKey, domainUrlKey]).subscribe(result => {
      languageEnglishFooterMenuItem = new FooterMenuModel(result[languageEnglishKey], FooterMenuItemType.LanguageEnglish, result[languageEnglishKey]);
      languageFrenchFooterMenuItem = new FooterMenuModel(result[languageFrenchKey], FooterMenuItemType.LanguageFrench, result[languageFrenchKey]);
      privacyFooterMenuItem = new FooterMenuModel(result[privacyPolicyKey], FooterMenuItemType.PrivacyPolicy, result[privacyPolicyKey]);
      termsFooterMenuItem = new FooterMenuModel(result[termsConditionsKey], FooterMenuItemType.TermsAndConditions, result[termsConditionsKey]);
      faqFooterMenuItem = new FooterMenuModel(result[faqKey], FooterMenuItemType.FAQ, result[faqKey]);
      cookiePolicyFooterMenuItem = new FooterMenuModel(result[cookiePolicyKey], FooterMenuItemType.CookiePolicy, result[cookiePolicyKey]);
      contactUsFooterMenuItem = new FooterMenuModel(result[contactusKey], FooterMenuItemType.ContactUs, result[contactusKey]);
      bmwCaFooterMenuItem = new FooterMenuModel(result[bmwCaKey], FooterMenuItemType.BMWCA, result[bmwCaKey]);
      this.footerMenuItems = [];
      this.tempFooterMenuItems = [];
      this.addFooterMenuItems([languageEnglishFooterMenuItem, languageFrenchFooterMenuItem, privacyFooterMenuItem, termsFooterMenuItem, faqFooterMenuItem, cookiePolicyFooterMenuItem, contactUsFooterMenuItem, bmwCaFooterMenuItem]);
      this.privacyUrl = result[privacyPolicyUrlKey];
      this.domainUrl = result[domainUrlKey];
    });
  }

  private addFooterMenuItems(footerMenuItems: FooterMenuModel[]) {
    this.footerMenuItems.unshift(...footerMenuItems);
    this.tempFooterMenuItems.unshift(...footerMenuItems);
  }

  public onFooterMenuItemSelect(event: any) {
    const isNewTab = true;
    if (event)
      switch (event.eventName) {
        case FooterMenuItemType.LanguageEnglish:
          this.toogleToEnglish();
          break;
        case FooterMenuItemType.LanguageFrench:
          this.toogleToFrench();
          break;
        case FooterMenuItemType.PrivacyPolicy:
          this.routerService.navigateToExternalUrl(this.privacyUrl, isNewTab);
          break;
        case FooterMenuItemType.TermsAndConditions:
          this.routerService.navigateToNewTabWithParam(route.termsview, this.languageService.getLanguageParameter());
          break;
        case FooterMenuItemType.FAQ:
          this.routerService.navigateToFaqWithLang(this.languageService.getCurrentLanguage());
          break;
        case FooterMenuItemType.CookiePolicy:
          this.routerService.navigateToCookiePolicy();
          break;
        case FooterMenuItemType.ContactUs:
          let contactUs: string = "";
          this.translateService.get('footer.contact-us-navigation').subscribe((res: any) => {
            contactUs = res;
          });
          this.routerService.navigateToFaqWithLang(this.languageService.getCurrentLanguage(), contactUs);
          break;
        case FooterMenuItemType.BMWCA:
          this.routerService.navigateToExternalUrl(this.domainUrl, isNewTab);
          break;
      }
  }

  ngOnDestroy(): void {
    this.dataloadedSubScription.unsubscribe();
  }

}
