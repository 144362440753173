import { EnvironmentConfig } from "projects/_shared/models/configuration/environment.config";
import { Environment } from "projects/_shared/models/enums";
import { Tile } from 'customerdigital-ui-lib';

export const environment: EnvironmentConfig = {
  ENV: Environment.DEV,
  PRODUCTION: true,
  LOGIN_URL: "https://login-i.bmwusa.com/oneid/#/login",
  LOGOUT_URL: "https://login-i.bmwusa.com/oneid/#/logout",
  SSO_REDIRECT_URL: "https://devprofile.bmw.ca/sso",
  GATEWAY_API_BASE_URL: "https://devprofile.bmw.ca",
  CONSOLE_LOGGING_ENABLED: true,
  APP_LOGGING_ENABLED: true,
  BASE_URL: "https://devprofile.bmw.ca/",
  PROFILE_URL: "https://login-i.bmwusa.com/oneid/#/profile",
  CLIENT_CONFIG: [
    {
      autoredirect: true,
      loginUri: "https://dev-myaccount.bmw.ca/",
      tileClass: "my-account-bmw",
      openInNewTab: false,
      creditCardTile: "",
      ssoLoginUri: "",
      cardHolderSsoEnabled: false,
      samlBase64: "",
      clientId: "",
      redirectUri: "",
      title: new Tile("")
    }
  ],
  IDLE_SESSION: 2,
  TIME_OUT_SESSION: 1,
  GLOBAL_NAV_DOMAIN_URL: "",
  PRIVACY_POLICY_URL: "https://www.bmw.ca/en/footer/metanavigation/Privacy_policy.html",
  MY_DOMAIN_URL: "https://www.bmw.ca/en/home.html",
  CONTACT_US: "",
  EPAAS_SCRIPT_URL: "https://www.bmw.com/etc/clientlibs/wcmp/consentcontroller.fallback/epaas.js",
  DEVICE_TOKEN_URL: "",
  MYPROFILE_URL: ""
};
